import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import ContentSectionList from "../React/KnowledgeBase/ContentSectionList.tsx";

export default defineComponent(AdminCategoryManager);

function AdminCategoryManager() {

    this.initCategoryManager = function () {
        const categoryEditor = this;

        const root = ReactDom.createRoot($(categoryEditor.node).get(0));
        root.render(
            <ContentSectionList
                categoryId={categoryEditor.categoryId}
                baseUri={categoryEditor.baseUri}>
            </ContentSectionList>
        );
    };

    this.after('initialize', function () {
        this.shopId = $(this.node).data('shop-id');
        this.categoryId = $(this.node).data('category-id');
        this.baseUri = $(this.node).data('base-uri');
        this.initCategoryManager();
    });
}
