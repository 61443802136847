import React from 'react';
import { Badge } from "react-bootstrap";
import mixam from '../../../boot/mixam.js';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { CELL_TYPES } from './DataTableConstants';
import { BSIcon, ExpandTable, Thumbnail } from './StyledDataTableNet';
import VideoEvents from './components/cells/VideoEvents';
import TimeBox from './components/cells/TimeBox';
import DeliveryDropdown from "./components/cells/DeliveryDropdown";
import ShowMoreText from 'react-show-more-text';
import ArticleStatus from './modals/ArticleStatus/ArticleStatus';
import ApiRequestLog from './modals/ApiRequestLog/ApiRequestLog';
import CreditControlStatus from './modals/CreditControl/CreditControlStatus';
import CustomerCreditFormModal from './modals/CustomerCreditForm/CustomerCreditForm';
import DispatchDate from './modals/DispatchDate/DispatchDate';
import DisputeAction from './modals/DisputeAction/DisputeAction';
import DisputeCreditActionFormModal from './modals/DisputeCreditActionForm/DisputeCreditActionForm';
import ReconcileDelivery from './modals/Delivery/ReconcileDelivery';
import ResellerCommand from './modals/ResellerCommand/ResellerCommand';
import ReconciliationFormModal from './modals/AccountsPayable/ReconciliationFormModal';
import TaxExemptionActionFormModal from './modals/TaxExemptionActionForm/TaxExemptionActionForm';
import ProofUploadForm from './modals/ProofUploadForm/ProofUploadForm';
import Remarks from './modals/Remarks/Remarks';
import SampleRequest from './modals/SampleRequest/SampleRequest';
import PublisherSettlement from './modals/PublisherSettlement/PublisherSettlement';
import PublishingHouseSettlement from './modals/PublishingHouseSettlement/PublishingHouseSettlement';
import ResellerGroup from "../../constants/reseller-groups.js";
import Checkbox from "./components/cells/Checkbox";
import InvoiceRequeueAction from "./modals/InvoiceRequeueAction/InvoiceRequeueAction";
import { MessageEditorModal } from "../../react/message-editor";
import { MODE } from "../../react/message-editor/types";
import ViewPurchaseOrderModal from "./modals/AccountsPayable/ViewPurchaseOrderModal";
import InvoiceRefundApproval from "./modals/CreditControl/InvoiceRefundApproval";
TimeAgo.addDefaultLocale(en);
export var getTableCell = function (type, rowData, columnName) {
    var data = rowData[columnName];
    switch (type) {
        case CELL_TYPES.SHOW_MORE_TEXT:
            if (!data) {
                return React.createElement("div", null);
            }
            return (React.createElement(ShowMoreText, { lines: data.lines, more: "More", less: "Less", expanded: false, truncatedEndingComponent: "... ", keepNewLines: true }, data.text));
        case CELL_TYPES.IMAGE:
        case CELL_TYPES.THUMBNAIL:
            if (!data) {
                return React.createElement("div", null);
            }
            return (React.createElement(Thumbnail, { onClick: function () { return window.open(data.href); } },
                React.createElement("img", { src: data.src })));
        case CELL_TYPES.LINK:
            if (!data) {
                return React.createElement("div", null);
            }
            return React.createElement("a", { href: data.href, target: data.target, className: "".concat(data.className ? data.className : '') }, data.caption);
        case CELL_TYPES.LINK_LIST:
            if (!data || data.length === 0) {
                return React.createElement("div", null);
            }
            return (React.createElement("div", null,
                React.createElement("ul", { className: "list-unstyled" }, data.map(function (link) { return (React.createElement("li", { className: "pb-2" },
                    React.createElement("a", { key: link.href, href: link.href, target: link.target, className: link.className ? link.className : '' }, link.caption))); }))));
        case CELL_TYPES.BUTTON:
            if (!data) {
                return React.createElement("div", null);
            }
            return (React.createElement("a", { className: data.className, href: data.href, target: data.target, key: data.href, role: "button" },
                data.iconClassname && React.createElement("i", { className: data.iconClassname }),
                data.caption));
        case CELL_TYPES.ICON:
            var iconClassname = (typeof data === 'object') ? data.icon : data;
            return (React.createElement("div", null,
                React.createElement(BSIcon, { className: iconClassname, "aria-hidden": 'true' })));
        case CELL_TYPES.DATETIME:
            if (!data)
                return React.createElement("div", null);
            return (React.createElement("div", null, new Intl.DateTimeFormat(getShopLocale(), { dateStyle: 'short', timeStyle: 'medium' }).format(data)));
        case CELL_TYPES.DATE:
            if (!data)
                return React.createElement("div", null);
            return (React.createElement("div", null, new Intl.DateTimeFormat(getShopLocale(), { dateStyle: 'short' }).format(data)));
        case CELL_TYPES.TIMEBOX:
            return (React.createElement(TimeBox, { data: data }));
        case CELL_TYPES.USER:
            return (React.createElement("div", null, data.name));
        case CELL_TYPES.ARTWORK:
        case CELL_TYPES.BOOLEAN:
            return (React.createElement("i", { className: "bi ".concat(data ? 'bi-check-lg' : '') }));
        case CELL_TYPES.LIST:
            return (React.createElement("ul", { className: "list-unstyled p-0" }, data && data.map(function (groupName) { return React.createElement("li", null, groupName); })));
        case CELL_TYPES.VIDEO_EVENTS:
            return (React.createElement(VideoEvents, { data: data }));
        case CELL_TYPES.BADGE:
            return React.createElement(Badge, { bg: data.colour, style: { textWrap: 'wrap' } }, data.label);
        case CELL_TYPES.CUSTOMER_CREDIT_MODAL:
            return (React.createElement(CustomerCreditFormModal, { line: data }));
        case CELL_TYPES.DISPATCH:
            var dateDispatch = {
                dateDispatch: rowData.dateDispatch,
                finalDate: rowData.finalDate ? rowData.finalDate : rowData.dateDispatch,
                delays: rowData.delays,
                id: rowData.id
            };
            return (React.createElement(DispatchDate, { line: dateDispatch }));
        case CELL_TYPES.COMMAND: {
            var line_1 = {
                id: rowData.id,
                statusText: rowData.status,
                status: rowData.status
            };
            return React.createElement(ArticleStatus, { line: line_1 });
        }
        case CELL_TYPES.RECONCILIATION_FORM_MODAL: {
            var line_2 = {
                fulfillmentDispute: rowData.fulfillmentDispute,
                productAmount: rowData.actualCost ? rowData.actualCost : '',
                extraAmount: rowData.actualExtra ? rowData.actualExtra : '',
                fulfillmentRemark: rowData.fulfillmentRemark
            };
            return React.createElement(ReconciliationFormModal, { line: line_2, orderId: rowData.orderId, itemId: rowData.itemId });
        }
        case CELL_TYPES.RESELLERCOMMAND:
            var line = {
                id: rowData.id,
                status: rowData.reseller,
                declineReason: rowData.rejectionReason
            };
            return React.createElement(ResellerCommand, { line: line });
        case CELL_TYPES.DELIVERIES:
            return React.createElement(DeliveryDropdown, { data: data });
        case CELL_TYPES.ROLES:
            var colours_1 = { User: 'primary', Verbose: 'secondary', Supplier: 'success', Mixam: 'danger', Admin: 'warning', Monitor: 'info', unclaimed: 'dark', Reseller: 'info', Dev: 'danger', Credit_control: 'warning', Cms: 'success', Catalogue_admin: 'danger', Catalogue_observer: 'info', External_observer: 'danger', External_agency: 'danger' };
            var cell = data.map(function (role) {
                return React.createElement("span", { className: "badge rounded-pill bg-".concat(colours_1[role.role]), key: role.role }, role.role);
            });
            return React.createElement("div", { style: {
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    display: 'flex',
                    gap: '0.5rem'
                } }, cell);
        case CELL_TYPES.MULTIBUTTON:
            var buttons = data.map(function (obj, i) { return React.createElement("a", { className: "btn btn-".concat(obj.className, " me-1"), href: obj.href, onClick: function (e) { return obj.callback(e); }, key: obj.href }, obj.caption); });
            return (React.createElement("div", { className: "w-100 text-end" }, buttons));
        case CELL_TYPES.TAX_EXEMPTION_ACTION:
            return React.createElement(TaxExemptionActionFormModal, { line: data });
        case CELL_TYPES.DISPUTE_CREDIT_ACTION:
            return React.createElement(DisputeCreditActionFormModal, { line: rowData });
        case CELL_TYPES.DISPUTE_ACTION:
            return React.createElement(DisputeAction, { line: rowData });
        case CELL_TYPES.CREDIT_COMMAND:
            return React.createElement(CreditControlStatus, { line: rowData, status: 1, target: '' });
        case CELL_TYPES.SAMPLES_REQUEST_ACTION:
            return React.createElement(SampleRequest, { line: rowData });
        case CELL_TYPES.API_LOG_MODAL:
            return React.createElement(ApiRequestLog, { line: rowData });
        case CELL_TYPES.REMARKS:
            if (!data)
                return React.createElement("div", null);
            return React.createElement(Remarks, { line: { data: data } });
        case CELL_TYPES.PROOF_UPLOAD_MODAL:
            return data ? React.createElement(ProofUploadForm, { line: rowData }) : React.createElement("div", null);
        case CELL_TYPES.CHECKBOX:
            return React.createElement(Checkbox, { data: data });
        case CELL_TYPES.PUBLISHER_SETTLEMENT_MODAL:
            return React.createElement(PublisherSettlement, { line: rowData });
        case CELL_TYPES.PUBLISHING_HOUSE_SETTLEMENT_MODAL:
            return React.createElement(PublishingHouseSettlement, { line: rowData });
        case CELL_TYPES.DELIVERY_RECONCILIATION:
            return React.createElement(ReconcileDelivery, { line: rowData });
        case CELL_TYPES.INVOICE_JOB_REQUEUE:
            return React.createElement(InvoiceRequeueAction, { data: data });
        case CELL_TYPES.MESSAGES_EDITOR:
            return (React.createElement(MessageEditorModal, { application: rowData.application, messageKey: rowData.key, locale: mixam.shop.locale, show: false, mode: MODE.ADMIN, onClose: function (data) { } }));
        case CELL_TYPES.ORDER_TYPE:
            return data && data.length ? (React.createElement("ul", { className: "list-unstyled m-0" }, data.map(function (orderType) {
                switch (orderType) {
                    /*
                     * If/when we add other types, we need to think
                     * about support for them here...
                     */
                    case 'PRINT_ON_DEMAND':
                        return (React.createElement("li", null, "POD"));
                    case 'FOURTHWALL':
                        return (React.createElement("li", null,
                            React.createElement("img", { src: "".concat(mixam.cdn, "/product-images/fourthwall-logo.png"), style: { maxWidth: '3rem' }, alt: "Fourthwall" })));
                    default:
                        return (React.createElement("li", null, orderType));
                }
            }))) : React.createElement("div", null);
        case CELL_TYPES.VIEW_PURCHASE_ORDER_MODAL:
            return data ? React.createElement(ViewPurchaseOrderModal, { fulfilmentId: data }) : React.createElement(React.Fragment, null);
        case CELL_TYPES.INVOICE_REFUND_ACTION: {
            return React.createElement(InvoiceRefundApproval, { invoiceRefund: rowData });
        }
        default:
            return (React.createElement("div", null, "Cell renderer needed"));
    }
};
export var ExpandedComponent = function (_a, columns) {
    var data = _a.data;
    var formatted = columns.map(function (column) {
        var colData = React.createElement("div", null, data[column.data]);
        if (checkObjContainsValue(CELL_TYPES, column.type)) {
            colData = getTableCell(column.type, data, column.data);
        }
        return (React.createElement("tr", { className: "hide-".concat(column.hide), key: "".concat(column.name, "-").concat(column.data) },
            React.createElement("td", null, column.name),
            React.createElement("td", null, colData)));
    });
    return (React.createElement(ExpandTable, null,
        React.createElement("tbody", null, formatted)));
};
export var round = function (value, decimals) {
    return Number(parseFloat(String(value)).toFixed(decimals));
};
export var checkObjContainsValue = function (obj, value) {
    return Object.values(obj).indexOf(value) > -1;
};
export var formatAsCurrency = function (col, row) {
    var rounded = round(row[col.data], col.decimal) || 0.0;
    var numberFormatter = Intl.NumberFormat(mixam.shop.locale.replace('_', '-'));
    var formatted = numberFormatter.format(rounded);
    var pre = rounded ? mixam.shop.currency.prefix : '';
    return "".concat(pre).concat(formatted);
};
export var getShopLocale = function () {
    return mixam.shop.locale.replace("_", "-");
};
export var getTableCellValue = function (column, row) {
    var data = row[column.data];
    if (!data) {
        switch (column.type) {
            case 'number':
                return 0.00;
            default:
                return '';
        }
    }
    switch (column.type) {
        case 'number':
            return column.currency ? parseFloat(data).toFixed(column.decimal) : data;
        case CELL_TYPES.IMAGE:
        case CELL_TYPES.THUMBNAIL:
            return data.src;
        case CELL_TYPES.LINK:
            return data.caption;
        case CELL_TYPES.DATETIME:
        case CELL_TYPES.TIMEBOX:
            return new Intl.DateTimeFormat(getShopLocale(), { dateStyle: 'short', timeStyle: 'medium' }).format(data);
        case CELL_TYPES.DATE:
        case CELL_TYPES.DISPATCH:
            return new Intl.DateTimeFormat(getShopLocale(), { dateStyle: 'short' }).format(data);
        case CELL_TYPES.USER:
            return data.name;
        case CELL_TYPES.ROLES:
            return JSON.stringify(data);
        case CELL_TYPES.RESELLERCOMMAND:
            if (row.reseller) {
                return ResellerGroup[row.reseller];
            }
            return '';
        case CELL_TYPES.BADGE:
            return data.label;
        case CELL_TYPES.BOOLEAN:
        case CELL_TYPES.ARTWORK:
            return data === true ? 'Yes' : 'No';
        case 'command':
            return data.status;
        case CELL_TYPES.ICON:
            return (typeof data === 'object') ? data.caption : data;
        case CELL_TYPES.SHOW_MORE_TEXT:
            return data.text;
        case CELL_TYPES.MULTIBUTTON:
        case CELL_TYPES.BUTTON:
        case CELL_TYPES.CREDIT_COMMAND:
        case CELL_TYPES.DISPUTE_ACTION:
        case CELL_TYPES.DELIVERY_RECONCILIATION:
        case CELL_TYPES.DISPUTE_CREDIT_ACTION:
        case CELL_TYPES.TAX_EXEMPTION_ACTION:
        case CELL_TYPES.VIDEO_EVENTS:
        case CELL_TYPES.CUSTOMER_CREDIT_MODAL:
        case CELL_TYPES.MESSAGES_EDITOR:
            return '';
        case 'list':
            if (data) {
                try {
                    return data.join(', ');
                }
                catch (e) {
                    return data.toString();
                }
            }
            return '';
        default:
            return data;
    }
};
